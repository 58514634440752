/* eslint-disable */
import Vue from 'vue'
import VueRouter from 'vue-router'
import checkAuth from '../middlewares/Auth'

Vue.use(VueRouter);
const DEFAULT_TITLE = 'B-Systems';
const routes = [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            requiresAuth: false,
        },
        component: () =>
            import('../pages/login/Login'),
    },
    {
        path: '/configuracoes',
        name: 'Painel Admin',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/painelAdmin/Painel'),
    },
    {
        path: '/estabelecimentos',
        name: 'Estabelecimentos',
        meta: {
            requiresAuth: false,
        },
        component: () =>
            import('../pages/estabelecimento/SelecaoEstabelecimento'),
    },
    {
        path: '/selecaoDiaMes',
        name: 'Selecao dia mes',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/agendamentos/SelecaoDiaMesAgendamento'),
    },
    {
        path: '/selecaoHora',
        name: 'Selecao Hora',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/agendamentos/SelecaoHoraAgendamento'),
    },
    {
        path: '/servicos',
        name: 'Servicos',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/servicos/Servicos'),
    },
    {
        path: '/configuracoes-servicos',
        name: 'Listagem Servicos',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/servicos/ListagemServicos'),
    },
    {
        path: '/cadastroServico',
        name: 'Cadastro Servico',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/servicos/CadastroServico'),
    },
    {
        path: '/home',
        name: 'Home',
        meta: {
            requiresAuth: true,
        },
        component: () => import('../pages/home/Home'),
    },
    {
        path: '/agenda',
        name: 'Horarios Agendamentos',
        props: true,
        meta: {
            requiresAuth: true,
        },
        component: () => import('../pages/agendamentos/HorariosAgendamento'),
    },
    {
        path: '/detalhesAgendamento',
        name: 'Detalhes Agendamento',
        props: true,
        meta: {
            requiresAuth: true,
        },
        component: () => import('../pages/agendamentos/DetalhesAgendamento'),
    },
    {
        path: '/cadastroAgendamento',
        name: 'Cadastro Agendamento',
        props: true,
        meta: {
            requiresAuth: true,
        },
        component: () => import('../pages/agendamentos/CadastroAgendamento'),
    },

    {
        path: '/agendamentos',
        name: 'Meus Agendamentos',
        props: true,
        meta: {
            requiresAuth: true,
        },
        component: () => import('../pages/agendamentos/MeusAgendamentos'),
    },

    {
        path: '/prestadores',
        name: 'Prestadores',
        props: true,
        meta: {
            requiresAuth: true,
        },
        component: () => import('../pages/agendamentos/SelecaoPrestadorServico'),
    },

    {
        path: '/cadastroProduto',
        name: 'Cadastro Produto',
        props: true,
        meta: {
            requiresAuth: true,
        },
        component: () => import('../pages/produtos/CadastroProdutos'),
    },
    {
        path: '/configuracoes-produtos',
        name: 'Listagem Produtos',
        props: true,
        meta: {
            requiresAuth: true,
        },
        component: () => import('../pages/produtos/ListagemProdutos'),
    },
    {
        path: '/cadastroUsuario',
        name: 'Cadastro Usuario',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/usuarios/CadastroUsuarios'),
    },
    {
        path: '/configuracoes-usuarios',
        name: 'Lista Usuarios',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/usuarios/ListagemUsuarios'),
    },
    {
        path: '/dashboard-financeiro',
        name: 'Financeiro',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/financeiro/Financeiro'),
    },
    {
        path: '/configuracoes-grupos',
        name: 'Listagem Grupos',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/grupos/ListagemGrupos'),
    },
    {
        path: '/cadastroGrupos',
        name: 'Cadastro Grupos',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/grupos/CadastroGrupos'),
    },
    {
        path: '/configuracoes-assinaturas',
        name: 'Cadastro Planos',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/planos/Planos'),
    },
    {
        path: '/configuracoes-listagem-assinaturas',
        name: 'Listagem planos admin',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/planos/ListagemPlanosAdmin'),
    },
    {
        path: '/listagem-assinaturas',
        name: 'Listagem planos',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/planos/ListagemPlanos'),
    },
    {
        path: '/configuracoes-horario-funcionamento',
        name: 'Horários Funcionamento',
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import('../pages/horariosFuncionamento/HorariosFuncionamento'),
    },
];

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

const router = new VueRouter({
    mode: 'history',
    routes,
});

// const isAuthenticated = async (login, senha) =>{
//   return checkAuth(login, senha)
// }

// router.beforeEach(async (to, from, next) => {
//     console.log(to)
    // if (to.matched.some((record) => record.meta.requiresAuth)) {
    //     console.log("0")
    //     if (true == true) {
    //         next()
    //         console.log("1")
    //     } else {
    //         console.log("2")
    //         next('/')
    //     }
    // } else {
    //     console.log("3")
    //     if(!to.query.id){
    //         next('/estabelecimentos')
    //     }else{
    //         next()
    //     }
        
    // }
// });
// router.beforeEach(async (to, from, next) => {
//     if (to.matched.some(record => record.meta.requiresAuth)) {
//         if (true) {
//             next();
//         } else {
//             next('/');
//         }
//     } else {
//         if (!to.query.id && to.name != "Estabelecimentos") {
//             next('/estabelecimentos');
//         } else {
//             next();
//         }
//     }
// });
router.beforeEach(async (to, from, next) => {
    Vue.prototype.$subdominio = to.query.empresa;

    let dadosUsuarioLogado = localStorage.getItem("dadosUsuarioLogado");
    // REVER
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if(!dadosUsuarioLogado){
            next('/home'); // Redirecione para a página de login ou outra página de erro
        }
        if (true === true) {
            next();
        } else {
            next('/home'); // Redirecione para a página de login ou outra página de erro
        }
    } else {
        // Em rotas não autenticadas, irá validar se há um subdominio na query params.
        if (!to.query.empresa && to.name !== "Estabelecimentos") {
            next('/estabelecimentos');
        } else {
            next();
        }
    }

});

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        let dadosUsuarioLogado = JSON.parse(localStorage.getItem("dadosUsuarioLogado"));

        document.title = dadosUsuarioLogado ? capitalizeFirstLetter(dadosUsuarioLogado[0].subdomain) : DEFAULT_TITLE;
    });
});

export default router