<template>
  <Transition :name="transitionName">
    <b-col
      :sm="colSM"
      :md="colMD"
      :lg="colLG"
      :xl="colXL"
      v-if="showMyDiv"
      :class="`pt-0 pr-0 card-inner ml-3 mr-2`"
    >
      <b-card
        :sub-title="subtitulo"
        :title="titulo"
        body-text-variant="dark"
        body-class="w-100 h-100 p-0"
      >
        <b-col
          :class="`w-100 h-100 ${containerSlotClass}`"
          style="overflow-y: auto; height: 100%"
        >
          <slot></slot>
        </b-col>
      </b-card>
    </b-col>
  </Transition>
</template>


<script>
import Mixin from "../core/Mixin";

export default {
  mixins: [Mixin],
  name: "Card",
  data() {
    return {
      showMyDiv: false,
      cardHeight: "",
    };
  },
  props: {
    titulo: {
      type: String,
      // required: true,
    },
    subtitulo: {
      type: String,
      // required: true,
    },
    colSM: {
      type: String,
      required: false,
      default: "12",
    },
    colMD: {
      type: String,
      required: false,
      default: "12",
    },
    colLG: {
      type: String,
      required: false,
      default: "12",
    },
    colXL: {
      type: String,
      required: false,
      default: "12",
    },
    alignRight: {
      type: Boolean,
      default: false,
    },
    logoUrl: {
      type: String,
      default: "",
    },
    containerSlotStyle: {
      type: String,
      default: "",
    },
    containerSlotClass: {
      type: String,
      default: "",
    },
    transitionName: {
      type: String,
      required: false,
      default: "fade",
    },
  },

  mounted() {
    this.showMyDiv = true;
  },

  methods: {},
};
</script>

<style>
div .card {
  overflow: hidden !important;
  margin-right: 30px !important;
}

.card-body {
  /* height: 100% !important; */
  /* background-color: #f2f2f2; */
  background-color: var(--cor-fundo);
  /* overflow: hidden !important; */
  /* overflow-x: hidden; */
}

.card-img {
  padding-left: 2vh;
  padding-right: 2vh;
  width: 20px;
  height: 60px;
}

.card {
  border-radius: 0 !important;
  border: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.5s ease;
}

.slide-right-enter {
  transform: translateX(100%);
}

.slide-right-leave-to {
  transform: translateX(-100%);
}
</style>
