<template>
  <div>
    <vue-navigation-bar
      :options="navbarOptions"
      @vnb-item-clicked="changeRoute"
    />

    <sidebar-menu
      :collapsed="true"
      :menu="menu"
      widthCollapsed="55px"
      :hideToggle="false"
      @toggle-collapse="onToggleCollapse"
      :rtl="false"
      theme="dark-theme"
    >
      <div slot="header" class="text-center">
        <b-img-lazy
          v-if="!sidebarOpened"
          :rounded="false"
          :src="require('../assets/mb-solucoes.png')"
          :width="250"
          :height="100"
        >
        </b-img-lazy>
      </div>
      <!-- <template #footer>footer</template> -->
      <span style="font-size: 1.5em" slot="toggle-icon">
        <i v-if="!sidebarOpened" class="fa-solid fa-toggle-on"></i>
        <i v-if="sidebarOpened" class="fa-solid fa-toggle-off"></i>
      </span>

      <div v-if="!sidebarOpened" slot="footer" class="text-center">
        <p class="service-details" style="font-size: 0.9em">
          Desenvolvido por <b>B-systems</b>
        </p>
      </div>
    </sidebar-menu>
  </div>
</template>

<script>
import Mixin from "../core/Mixin";
import brandImage from "../assets/mb-solucoes.png";

export default {
  mixins: [Mixin],
  name: "Navbar",

  data: function () {
    return {
      sidebarOpened: true,
      menu: [],
      nomeUsuario: "",
      imagens: {},
      navbarOptions: {
        elementId: "main-navbar",
        class: "nk-header is-light",
        isUsingVueRouter: true,
        mobileBreakpoint: 50,
        brandImagePath: "Home",
        isUsingVueRouter: true,
        brandImage: brandImage,
        brandImageAltText: "brand-image",
        collapseButtonOpenColor: "black",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "MB Soluções",
        tooltipAnimationType: "perspective",
        tooltipPlacement: "bottom",
        menuOptionsLeft: [],
        menuOptionsRight: [
          {
            type: "link",
            text: "",
            arrowColor: "#659CC8",
            iconRight: `
              <li class="dropdown user-dropdown">
                <a href="#" class="dropdown-toggle me-n1" data-bs-toggle="dropdown">
                    <div class="user-toggle">
                        <div  style="background-color:var(--cor-primaria)" class="user-avatar sm"><em class="icon ni ni-user-alt"></em></div>
                        <div class="user-info d-none d-xl-block">
                            <div class="user-name">${
                              this.getItemsFromLocalStorage(
                                "dadosUsuarioLogado"
                              )[0]?.user?.name ?? ""
                            }</div>
                        </div>
                    </div>
                </a>
                <div class="dropdown-menu dropdown-menu-md dropdown-menu-end">
                    <div class="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                        <div class="user-card">
                            <div class="user-avatar"><span>AB</span></div>
                            <div class="user-info">
                                <span class="lead-text">Abu Bin Ishtiyak</span>
                                <span class="sub-text">info@softnio.com</span>
                            </div>
                        </div>
                    </div>
                    <div class="dropdown-inner">
                        <ul class="link-list">
                            <li><a href="/demo2/user-profile-regular.html"><em class="icon ni ni-user-alt"></em><span>View Profile</span></a></li>
                            <li><a href="/demo2/user-profile-setting.html"><em class="icon ni ni-setting-alt"></em><span>Account Setting</span></a></li>
                            <li><a href="/demo2/user-profile-activity.html"><em class="icon ni ni-activity-alt"></em><span>Login Activity</span></a></li>
                        </ul>
                    </div>
                    <div class="dropdown-inner">
                        <ul class="link-list">
                            <li><a href="#"><em class="icon ni ni-signout"></em><span>Sign out</span></a></li>
                        </ul>
                    </div>
                </div>
              </li>
            `,
            subMenuOptions: [
              {
                isLinkAction: true,
                type: "link",
                text: "Meu Perfil",
                path: { name: "" },
                iconLeft:
                  '<li><a href="/demo2/lms/admin-profile.html"><em class="icon ni ni-user-alt"></em><span></span></a></li>',
              },
              {
                type: "hr",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Planos",
                path: { name: "Listagem planos" },
                iconLeft:
                  '<li><a href="/demo2/lms/admin-profile.html"><em class="icon ni ni-reports"></em><span></span></a></li>',
              },
              {
                type: "hr",
              },
              {
                isLinkAction: true,
                type: "link",
                text: "Sair",
                path: { name: "Login" },
                iconLeft:
                  '<li><a href="#"><em class="icon ni ni-signout"></em></a></li>',
              },
            ],
          },
        ],
      },
    };
  },

  async mounted() {
    this.obterImagens();
    this.montarImagensEmpresa();
    this.obterNomeUsuarioLogado();
    this.obterMenusPorTipoUsuario();
  },

  methods: {
    onToggleCollapse(collapsed) {
      this.sidebarOpened = collapsed;
    },

    obterNomeUsuarioLogado() {
      this.nomeUsuario = this.getItemsFromLocalStorage("dadosUsuarioLogado")[0];
    },

    obterImagens() {
      this.imagens = this.getItemsFromLocalStorage("imagensEmpresa")[0];
    },

    montarImagensEmpresa() {
      this.navbarOptions.brandImage = this.imagens?.logo ?? "";
    },

    changeRoute(route) {
      console.log(route);
      if (route == "Planos") {
        this.$router
          .push({
            name: "Listagem planos",
            params: {
              id: this.$store.getters.getPropriedades?.dadosUsuarioLogado?.user
                .id,
            },
          })
          .catch((e) => {
            console.error(e);
          });
      }

      if (route == "Meu Perfil" && this.$route.name != "Cadastro Usuario") {
        this.$router
          .push({
            name: "Cadastro Usuario",
            params: {
              id: this.$store.getters.getPropriedades?.dadosUsuarioLogado?.user
                .id,
            },
          })
          .catch((e) => {
            console.error(e);
          });
      }

      if (route == "Sair") {
        this.$router
          .push({
            path: `/login?empresa=${this.lodash.get(
              this.$store,
              "getters.getPropriedades.dadosUsuarioLogado.subdomain",
              this.lodash.get(
                this.getItemsFromLocalStorage("dadosUsuarioLogado"),
                "[0].subdomain",
                ""
              )
            )}`,
          })
          .catch(() => {
            this.$router.push({ path: "/estabelecimentos" });
          });
      }
    },

    obterMenusPorTipoUsuario() {
      let itensNavegacao = this.lodash.get(
        this.getItemsFromLocalStorage("dadosUsuarioLogado"),
        "[0].user.homeTools",
        []
      );

      this.menu = [
        {
          header: "Main Navigation",
          hiddenOnCollapse: false,
        },
        {
          href: "/home",
          title: "Inicio",
          icon: {
            element: "i",
            class: "fa-sharp fa-solid fa-house",
          },
        },
      ];

      itensNavegacao.map((item) => {
        this.menu.push({
          href: item.slug,
          title: item.name,
          icon: item.icon,
        });
      });

      this.menu.push({
        href: `/login?empresa=${this.lodash.get(
          this.$store,
          "getters.getPropriedades.dadosUsuarioLogado.subdomain",
          this.lodash.get(
            this.getItemsFromLocalStorage("dadosUsuarioLogado"),
            "[0].subdomain",
            ""
          )
        )}`,
        title: "Sair",
        icon: {
          element: "i",
          class: "fa-solid fa-arrow-right-from-bracket",
        },
      });
    },
  },
};
</script>

<style lang="scss">
#main-navbar {
  margin-left: 40px;
}
</style>

