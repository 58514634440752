import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store = new Vuex.Store({
  state() {
    return {
      propriedades: {},
      isLoading: false,
    }
  },
  getters: {
      getPropriedades(state) {
        return state.propriedades
      },

      isLoading(state){
        return state.isLoading
      }
  },
  mutations: {
    setPropriedades(state, propriedades) {
      if(propriedades){
        state.propriedades = {...state.propriedades, ...propriedades};
      }
      
    },

    setLoading(state, isLoading) {
      state.isLoading = isLoading
    }
  },

  actions: {
    setLoading(context, isLoading) {
      context.commit('setLoading', isLoading)
    }
  }
})

export default store
