<template>
  <b-row>
    <b-col :cols="cols" :xl="colXL" :lg="colLG" :md="colMD" :sm="colSM">
      <b-button
        variant="primary"
        class="m-1 col-6 col-sm-6 col-md-3"
        @click="sendMessage"
      >
        Teste socket
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import consumer from "../../consumer"; // Verifique o caminho correto conforme sua estrutura

export default {
  data() {
    return {
      subscription: null,
    };
  },

  mounted() {
    console.log("Starting connection to WebSocket Server");

    this.subscription = consumer.subscriptions.create(
      {
        channel: "PaymentStatusChannel",
      },
      {
        connected() {
          console.log("Connected to PaymentStatusChannel");
        },
        disconnected() {
          console.log("Disconnected from PaymentStatusChannel");
        },
        received(data) {
          console.log("Received data:", data);
        },
        rejected(data) {
          console.log("Rejected connection:", data);
        },
      }
    );

    console.log(this.subscription, "subsc");
  },

  methods: {
    sendMessage() {
      console.log("Sending message");
      this.subscription.perform("notify", {
        message: "Test message from Vue component",
      });
    },
  },

  props: {
    colXL: {
      type: Number,
      required: false,
    },
    colLG: {
      type: Number,
      required: false,
    },
    colMD: {
      type: Number,
      required: false,
    },
    colSM: {
      type: Number,
      required: false,
    },
    cols: {
      type: Number,
      required: false,
    },
    titulo: {
      type: String,
      required: false,
    },
    subtitulo: {
      type: String,
      required: false,
    },
    containerClass: {
      type: String,
    },
  },
};
</script>

<style scoped>
</style>
