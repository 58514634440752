<template>
  <div>
    <b-button
      v-bind="buttonProps"
      @click="handleClick"
      @mouseover="handleMouseOver"
      @mouseout="handleMouseOut"
    >
      {{ buttonText }}
    </b-button>
  </div>
</template>

<script>
export default {
    name: 'customButton',    
    props: {
        active: Boolean,
        block: Boolean,
        disabled: Boolean,
        href: String,
        size: String,
        variant: String,
        tag: {
        type: String,
        default: "button",
        },
        type: {
        type: String,
        default: "button",
        },
        value: {
        type: [String, Number],
        default: null,
        },
        pressed: {
        type: [Boolean, String],
        default: false,
        },
        autocomplete: String,
        autofocus: Boolean,
        name: String,
        required: Boolean,
        form: String,
        tabindex: [String, Number],
        id: String,
        ariaPressed: {
        type: [Boolean, String],
        default: null,
        },
        ariaLabel: String,
        ariaControls: String,
        ariaExpanded: {
        type: [Boolean, String],
        default: null,
        },
        ariaLive: String,
        ariaOwns: String,
        buttonText: {
        type: String,
        default: "Click me",
        },
    },
    methods: {
        handleClick() {
            this.$emit("button-click");
        },

        handleMouseOver() {
            this.$emit("button-mouseover");
        },

        handleMouseOut() {
            this.$emit("button-mouseout");
        },
    },
};
</script>

<style scoped>
/* Adicione estilos personalizados aqui, se necessário */
</style>
