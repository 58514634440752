<template>
  <b-row class="mr-4">
    <b-col
      v-if="itens && itens.length === 0"
      class="d-flex align-items-center justify-content-center mt-5 no-services-found"
    >
      <p>{{ textoSemItens }}</p>
    </b-col>

    <b-col
      :cols="cols"
      :xl="colXL"
      :lg="colLG"
      :md="colMD"
      :sm="colSM"
      v-else
      v-for="(item, index) in itens"
      :key="index"
      :class="`container`"
      @click="selected(item)"
    >
      <b-row
        align-v="center"
        :class="`m-1 p-2 service-container text-center ${
          item.containerClass ? item.containerClass : containerClass
        }`"
      >
        <div v-for="(chave, chaveIndex) in chaves" :key="chaveIndex">
          <component
            v-if="chave.tipo === 'text'"
            :class="lodash.get(chave, 'class', '')"
            :style="lodash.get(chave, 'style', '')"
            :is="lodash.get(chave, 'tag', 'p')"
            v-bind="chave.customProps"
          >
            {{
              chave.formatter
                ? chave.formatter(lodash.get(item, chave.chave, ""))
                : lodash.get(item, chave.chave, "")
            }}
          </component>

          <component
            v-if="chave.tipo === 'icon'"
            :style="lodash.get(chave, 'style', '')"
            :is="lodash.get(chave, 'tag', 'i')"
            :class="lodash.get(item, chave.chave, '')"
            v-bind="chave.customProps"
          >
          </component>

          <component
            v-if="chave.tipo === 'custom'"
            :class="lodash.get(chave, 'class', '')"
            :style="lodash.get(chave, 'style', '')"
            :is="lodash.get(chave, 'tag', 'p')"
            :src="lodash.get(item, chave.chave, '')"
            v-bind="chave.customProps"
          >
          </component>

          <div v-if="chave.tipo === 'array'">
            <Catalog
              :itens="lodash.get(item, chave.chave, [])"
              :chaves="chave.chavesArray"
              :colXL="3"
              :colLG="4"
              :colMD="6"
              :colSM="12"
              :cols="12"
              containerClass="text-dark border-larger rounded-0 border-primary"
              @selected="selected"
            />
          </div>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import lodash from "lodash";

export default {
  name: "Catalog",
  props: {
    itens: {
      type: Array,
      required: true,
    },
    chaves: {
      type: Array,
      required: true,
    },
    colXL: {
      type: Number,
      required: false,
    },
    colLG: {
      type: Number,
      required: false,
    },
    colMD: {
      type: Number,
      required: false,
    },
    colSM: {
      type: Number,
      required: false,
    },
    cols: {
      type: Number,
      required: false,
    },
    containerClass: {
      type: String,
      required: false,
    },
    textoSemItens: {
      type: String,
      default: "Não há itens para exibir",
    },
  },
  methods: {
    selected(selecionado) {
      this.$emit("selected", selecionado);
    },
  },
};
</script>

<style scoped>
@media (max-width: 992px) {
  .service-container {
    min-height: 35vh;
    padding: 2px;
  }
}

.main-container {
  background-color: #fff;
  padding: 30px;
  cursor: pointer;
}

.container {
  margin-top: 5px;
  cursor: pointer;
}

.border-bottom-large {
  border-bottom: lightgray 4px solid;
}

.no-services-found {
  font-size: 21px;
  color: #c2c2c2 !important;
}

.help-new-service {
  cursor: pointer;
}
</style>
