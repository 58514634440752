<template>
  <div id="B-Systems" style="overflow: hidden; position: relative">
    <notifications
      position="top right"
      classes="custom-notify vue-notification"
    />

    <Navbar
      v-if="
        $route && $route.name != 'Login' && $route.name != 'Estabelecimentos'
      "
    />

    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="ligth"
      opacity="0.85"
      blur="0px"
      rounded="sm"
    >
      <template #overlay>
        <b-col cols="12" style="width: 5vw"
          ><b-img :src="require('./assets/fade-stagger-squares.svg')"> </b-img
        ></b-col>
      </template>

      <router-view
        :class="
          $route && $route.name != 'Login' && $route.name != 'Estabelecimentos'
            ? 'ml-5'
            : ''
        "
      />
    </b-overlay>
  </div>
</template>


<script>
import Vue from "vue";
import Mixin from "./core/Mixin";
import { mapGetters, mapActions } from "vuex";

export default {
  mixins: [Mixin],
  data: function () {
    return {
      alturaRouterView: "80vh",
      alturaComandaAberta: "20vh",
      exibirContainerComanda: true,
      comandaAberta: {},
      iconComandaAberta: "arrows-angle-contract",
      componentKeyComanda: 0,
      exibirBotaoComanda: false,
    };
  },

  created() {
    if (this.$route.query && this.$route.query.empresa) {
      Vue.prototype.$subdominio = this.$route.query.empresa;
    } else {
      Vue.prototype.$subdominio = "B-Systems";
    }

    /*TO DO:
      Criar requisicao que buscará o css do subdominio

      - Se nao houver subdominio, irá redirecionar para selecao de subdominios.
    */
  },

  mounted() {
    this.changeStyle();
  },

  methods: {
    ...mapActions(["setLoading"]),
    changeStyle() {
      // const sheets = document.styleSheets;
      // const classesParaAlterar = new Set([
      //   ".btn-primary",
      //   ".v-sidebar-menu",
      //   ".vsm--link_level-1",
      //   ".vsm--icon",
      // ]); // Usar um Set para verificar de forma mais eficiente
      // for (let i = 0; i < sheets.length; i++) {
      //   const rules = sheets[i].cssRules || sheets[i].rules;
      //   if (!rules) continue; // Ignorar folhas de estilo que não possuem regras
      //   for (let j = 0; j < rules.length; j++) {
      //     const rule = rules[j];
      //     if (rule.selectorText) {
      //       const selectors = rule.selectorText.split(",").map((s) => s.trim());
      //       selectors.forEach((selector) => {
      //         if (classesParaAlterar.has(selector)) {
      //           rule.style.setProperty("background-color", "red");
      //         }
      //       });
      //     }
      //   }
      // }
    },
    obterRotaComComanda(route) {
      let rotasComComanda = ["Selecao dia mes", "Servicos", "Selecao Hora"];

      if (rotasComComanda.includes(route)) {
        this.exibirBotaoComanda = true;
        return true;
      } else {
        this.exibirBotaoComanda = false;

        return false;
      }
    },

    async exibirComanda(aberto) {
      this.comandaAberta = await this.$getStore("comanda");
    },
  },

  async updated() {
    this.componentKeyComanda = +1;
    this.comandaAberta = await this.$getStore("comanda");

    this.obterRotaComComanda(this.$route.name);
  },

  computed: {
    ...mapGetters(["isLoading"]),
  },
};
</script>


<style lang="scss">
#app {
  //font-family: Verdana, Geneva, sans-serif;
  color: #282a35;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  // background-color: #f2f2f2 !important;
}

.container-route-name {
  // color: #f2f2f2 !important;
  background-color: rgb(0, 96, 177);
}

.route-name {
  font-weight: 540;
}

.custom-notify {
  background-color: white;
  color: black;
  font-size: 16px;
}

.notification-content {
  font-size: 14px;
}

.notification-title {
  padding: 5px;
}
</style>
