<template>
  <b-row class="p-1">
    <b-col
      :class="`m-1 container-collapse  text-left p-3 rounded-bottom p-2 ${classContainer}`"
      cols="12"
    >
      <b-row @click="handleClick">
        <b-col>
          <h5 class="texto-catalogo">{{ title }}</h5>
        </b-col>

        <b-col class="d-flex justify-content-end mr-3">
          <b-icon
            class=""
            :icon="isOpened ? 'chevron-bar-up' : 'chevron-bar-down'"
            scale="1.4"
          />
        </b-col>
      </b-row>

      <b-collapse :visible="isOpened" class="p-1 container-collapse">
        <slot> </slot>
      </b-collapse>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "collapse",
  data: function () {
    return {
      isOpened: false,
    };
  },
  props: {
    disabled: Boolean,
    variant: String,
    title: String,
    contentVisible: Boolean,
    classContainer: String,
  },
  mounted() {
    this.isOpened = this.contentVisible;
  },

  methods: {
    handleClick() {
      if (!this.disabled) {
        this.isOpened = !this.isOpened;
        this.$emit("collapse-click");
      }
    },
  },
};
</script>

<style scoped>
.btn :active {
  background-color: red;
}
/* Adicione estilos personalizados aqui, se necessário */
</style>
