<template>
  <div>
    <b-table
      v-bind="tableProps"
      @row-clicked="handleRowClicked"
      @sort-changed="handleSortChanged"
    >
      <!-- Slot para a célula de foto -->
      <template v-slot:cell(photo)="data">
        <b-col class="d-flex justify-content-center">
          <b-img
            :src="
              data.item.photo && data.item.photo !== ''
                ? data.item.photo
                : require('../assets/sem_imagem.png')
            "
            height="60vh"
            width="60vw"
          ></b-img>
        </b-col>
      </template>

      <!-- Slot para ações -->
      <template v-slot:cell(actions)="row">
        <div class="text-center custom-row-cells">
          <b-dropdown id="dropdown-1" variant="outline-light" class="m-md-2">
            <template #button-content>
              <b-icon
                font-scale="2"
                icon="three-dots"
                variant="primary"
              ></b-icon>
            </template>

            <b-dropdown-item @click="cadastroUsuario(row.item.id)">
              <b-icon
                font-scale="1.3"
                icon="pencil-square"
                variant="primary"
              ></b-icon>
              Editar
            </b-dropdown-item>

            <b-dropdown-item>
              <b-icon font-scale="1.3" icon="trash" variant="danger"></b-icon>
              Remover
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "CustomTable",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    caption: String,
    responsive: Boolean,
    hover: Boolean,
    striped: Boolean,
    bordered: Boolean,
    outlined: Boolean,
    small: Boolean,
    fixed: Boolean,
    footClone: Boolean,
    tbodyTrClass: [String, Function],
    theadTrClass: [String, Function],
    busy: Boolean,
    busyTemplate: String,
    stacked: String,
    stackedSize: String,
    headVariant: String,
    footVariant: String,
    captionTop: Boolean,
    sortIcon: String,
    sortIconSize: String,
    noCollapse: Boolean,
    tbodyTrAttr: [Object, Function],
    theadTrAttr: [Object, Function],
    busyContext: Object,
    checkAllRows: Boolean,
    dark: Boolean,
    borderless: Boolean,
    filter: [String, RegExp],
  },
  computed: {
    tableProps() {
      return {
        items: this.items,
        fields: this.fields,
        caption: this.caption,
        responsive: this.responsive,
        hover: this.hover,
        striped: this.striped,
        bordered: this.bordered,
        outlined: this.outlined,
        small: this.small,
        fixed: this.fixed,
        footClone: this.footClone,
        tbodyTrClass: this.tbodyTrClass,
        theadTrClass: this.theadTrClass,
        busy: this.busy,
        busyTemplate: this.busyTemplate,
        stacked: this.stacked,
        stackedSize: this.stackedSize,
        headVariant: this.headVariant,
        footVariant: this.footVariant,
        captionTop: this.captionTop,
        sortIcon: this.sortIcon,
        sortIconSize: this.sortIconSize,
        noCollapse: this.noCollapse,
        tbodyTrAttr: this.tbodyTrAttr,
        theadTrAttr: this.theadTrAttr,
        busyContext: this.busyContext,
        checkAllRows: this.checkAllRows,
        dark: this.dark,
        borderless: this.borderless,
        filter: this.filter,
      };
    },
  },
  methods: {
    handleRowClicked(item, index, event) {
      this.$emit("row-clicked", { item, index, event });
    },
    handleSortChanged(sortedItems, event) {
      this.$emit("sort-changed", { sortedItems, event });
    },
    cadastroUsuario(id) {
      console.log("Editar usuário:", id);
    },
  },
};
</script>

<style scoped>
/* Estilos personalizados, se necessário */
</style>
