var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"sytle":"display:contents"}},[_c('b-modal',{ref:"modal-dinamico",attrs:{"scrollable":true,"size":"xl","title":_vm.placeholder,"header-text-variant":'light',"header-bg-variant":"dark","body-bg-variant":"dark","footer-bg-variant":"dark","centered":"","hideFooter":""},on:{"hidden":_vm.fecharModal}},[_c('b-table',{attrs:{"borderless":"","table-variant":"light","items":_vm.multiselectOptions,"stacked":"sm","dark":"","fields":_vm.fields,"striped":""},on:{"row-clicked":_vm.selecionarLinha}})],1),_c('FloatingLabel',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],class:_vm.modelValue || _vm.isMultiselect ? 'input__container--content' : '',attrs:{"config":{
      label: _vm.label,
      scale: false,
      height: 68,
      labelOffset: {
        top: 1,
        left: 5,
      },
      hasError: false,
      color: {
        focusColor: '#128CED',
        errorColor: '#ff0000',
        lineColor: '#128CED',
        blurredColor: 'black',
      },
    }}},[(!_vm.isMultiselect)?_c('b-form-input',{staticClass:"customInput",attrs:{"size":"md","state":_vm.state,"formatter":_vm.formatter,"type":_vm.inputType,"autocomplete":"off"},on:{"change":_vm.onChange},model:{value:(_vm.vmodel),callback:function ($$v) {_vm.vmodel=$$v},expression:"vmodel"}}):_c('multiselect',{ref:"multiselectCustom",attrs:{"size":"sm","options":[],"label":_vm.labelsMultiselect,"placeholder":_vm.placeholder,"selectLabel":"Selecione","selectedLabel":"Selecionado","deselectLabel":"Remover seleção","tag-placeholder":"","multiple":_vm.multiple,"trackBy":_vm.labelsMultiselect,"preventAutofocus":"","allowEmpty":false,"searchable":false,"close-on-select":true,"clearOnSelect":_vm.clearOnSelect},on:{"open":function($event){return _vm.exibirModalMultiselect(true)}},scopedSlots:_vm._u([{key:"noOptions",fn:function(){return [_vm._v("Sem registros")]},proxy:true},{key:"noResult",fn:function(){return [_vm._v("Nenhum registro encontrado")]},proxy:true}]),model:{value:(_vm.vmodel),callback:function ($$v) {_vm.vmodel=$$v},expression:"vmodel"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }