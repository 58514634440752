<template>
  <b-row
    :style="`background-image:url(${bgImage}); max-width:100vw; max-height:100vh; margin:0;`"
    :class="'justify-content-md-end fundo-login'"
  >
    <b-col
      sm="12"
      md="5"
      lg="4"
      xl="3"
      class="container-formulario-login text-center"
    >
      <b-col>
        <b-img-lazy
          :rounded="true"
          :src="
            empresa.urlImagem != undefined
              ? `${empresa.urlImagem}`
              : require('../../assets/mb-solucoes.png')
          "
          :width="250"
          :height="100"
          :blank="true"
        >
        </b-img-lazy>
      </b-col>

      <b-col class="mt-5 text-start" cols="12">
        <p class="service-description">{{ informacaoEtapaLogin }}</p>
      </b-col>

      <b-col
        class="text-center"
        v-for="input in inputs"
        v-bind:key="input.model"
      >
        <CustomInput
          class="text-center"
          :label="input.label"
          :placeholder="input.placeholder"
          :isMultiselect="input.isMultiselect"
          :multiselectOptions="input.multiselectOptions"
          :labelsMultiselect="input.labelsMultiselect"
          :inputType="input.inputType"
          :formatter="input.formatter"
          v-model="input.model"
          :valid="null"
          :id="input.id"
          v-on:changeValue="onChildUpdate"
        />
      </b-col>
      <b-col cols="12" style="margin-top: 5vh">
        <b-row class="text-center" style="align-content: space-between">
          <b-col cols="6" v-if="tipoLogin == 3">
            <b-button
              variant="outline-primary"
              class="col-12"
              v-on:click="mudarTipoLogin(1)"
            >
              Voltar
            </b-button>
          </b-col>
          <b-col cols="6" v-if="tipoLogin == 3">
            <b-button
              variant="outline-primary"
              class="col-12"
              v-on:click="criarConta()"
            >
              Criar Conta
              <b-icon icon="person-plus"></b-icon>
            </b-button>
          </b-col>

          <b-col cols="6" v-if="tipoLogin == 1">
            <b-button
              variant="outline-primary"
              class="col-12"
              v-on:click="mudarTipoLogin(3)"
            >
              Criar conta
              <b-icon icon="person-plus"></b-icon>
            </b-button>
          </b-col>
          <b-col cols="6" v-if="tipoLogin == 1">
            <b-button
              variant="outline-primary"
              class="col-12"
              v-on:click="realizarLogin(obterObjetoUsuarioPorTipo(1))"
            >
              Entrar
              <b-icon icon="arrow-bar-right"></b-icon>
            </b-button>
          </b-col>

          <b-col v-if="tipoLogin == 4" cols="12" class="mt-4">
            <b-button
              variant="outline-primary"
              class="col-12"
              v-on:click="criarConta(1)"
            >
              Entrar
              <!-- <b-icon icon="key-fill"></b-icon> -->
            </b-button>
            <b-button
              variant="outline-primary"
              class="mt-4 col-12"
              v-on:click="mudarTipoLogin(1)"
            >
              Voltar
            </b-button>
          </b-col>

          <b-col cols="6" v-if="tipoLogin == 5">
            <b-button
              variant="outline-primary"
              class="mt-4 col-12"
              v-on:click="mudarTipoLogin(1)"
            >
              Voltar
            </b-button>
          </b-col>
          <b-col v-if="tipoLogin == 5" class="mt-4">
            <b-button
              variant="primary"
              class="col-12"
              v-on:click="enviarCodigoRedefinicaoSenha()"
            >
              Continuar
              <!-- <b-icon icon="key-fill"></b-icon> -->
            </b-button>
          </b-col>

          <b-col cols="6" v-if="tipoLogin == 6">
            <b-button
              variant="outline-primary"
              class="mt-4 col-12"
              v-on:click="mudarTipoLogin(1)"
            >
              Voltar
            </b-button>
          </b-col>
          <b-col v-if="tipoLogin == 6" class="mt-4">
            <b-button
              variant="primary"
              class="col-12"
              @click="enviarRedefinicaoSenha()"
            >
              Confirmar
              <!-- <b-icon icon="key-fill"></b-icon> -->
            </b-button>
          </b-col>

          <b-col v-if="tipoLogin == 1" cols="12" class="mt-4">
            <b-button
              variant="primary"
              class="col-12"
              v-on:click="mudarTipoLogin(4)"
            >
              Continuar sem cadastro
              <!-- <b-icon icon="key-fill"></b-icon> -->
            </b-button>
          </b-col>
          <b-col v-if="tipoLogin == 2" cols="12" class="mt-4">
            <b-button
              variant="outline-primary"
              class="col-12"
              v-on:click="mudarTipoLogin(1)"
            >
              Acessar conta
              <!-- <b-icon icon="key-fill"></b-icon> -->
            </b-button>
          </b-col>
          <b-col v-if="tipoLogin == 1" cols="12" class="mt-4">
            <b-button
              variant="outline-secondary"
              class="col-12 border-0"
              v-on:click="mudarTipoLogin(5)"
            >
              Esqueci minha senha
              <!-- <b-icon icon="key-fill"></b-icon> -->
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-col>
  </b-row>
</template>

<script>
/* eslint-disable */

import Authentication from "../../services/authentication";

import Mixin from "../../core/Mixin";
import api from "../../http/index";

export default {
  components: {},
  mixins: [Mixin],
  data: function () {
    return {
      tipoLogin: 1,
      user: [],
      inputs: [],
      empresa: {},
      bgImage: "",
      informacaoEtapaLogin: "",
    };
  },
  mounted() {
    this.$el.addEventListener("keydown", this.handleKeyPress);
    this.setLoading(true);

    this.deleteItemFromLocalStorage("dadosUsuarioLogado");
    this.$setStoreUsuarioLogado({});
    this.obterInputs(this.tipoLogin);
    this.obterInformacoesSubdominio();
    this.obterInformacaoEtapaLogin();

    this.setLoading(false);
  },
  methods: {
    handleKeyPress(event) {
      if (event.key === "Enter") {
        this.realizarLogin(this.obterObjetoUsuarioPorTipo(1));
      }
    },

    async enviarCodigoRedefinicaoSenha() {
      try {
        this.setLoading(true);

        await api({
          method: "POST",
          url: `/auth/cellphone_recovery/generate_code`,
          data: {
            cellphone: this.user.cellphone.toString(),
            subdomain: this.$route.query.empresa,
          },
        });

        this.setLoading(false);

        this.$notify({
          title: "Mensagem enviada",
          text: `
            <p>Verifique seu celular, um código de recuperação de senha foi enviado a você.</p>
          `,
          duration: 5000,
          type: "success",
        });

        this.mudarTipoLogin(6);
      } catch (e) {
        console.log(e);
        this.setLoading(false);

        this.$notify({
          title: `${e?.response?.data?.errors?.base ?? "Ocorreu uma falha"}`,
          type: "warn",
          text: "",
          duration: 5000,
        });
      }
    },

    async enviarRedefinicaoSenha() {
      try {
        this.setLoading(true);

        let autenticacaoCodigo = await api({
          method: "POST",
          url: `/auth/cellphone_recovery/validate_code`,
          data: {
            cellphone: this.user.cellphone.toString(),
            code: this.user.code.toString(),
            subdomain: this.$route.query.empresa,
          },
        });

        await api({
          method: "PATCH",
          url: `/auth/password`,
          headers: {
            ...autenticacaoCodigo.headers,
          },
          data: {
            password: this.user.password,
            password_confirmation: this.user.passwordConfirmation,
          },
        });

        this.mudarTipoLogin(1);

        this.setLoading(false);

        this.$notify({
          title: "Redefinição de senha",
          text: `
            <p>Sua senha foi alterada com sucesso</p>
          `,
          duration: 5000,
          type: "success",
        });
      } catch (e) {
        this.setLoading(false);
        this.$notify({
          title: `${e?.response?.data?.errors?.base ?? "Ocorreu uma falha"}`,
          type: "warn",
          text: "",
          duration: 5000,
        });
      }
    },

    obterInformacaoEtapaLogin() {
      if (this.tipoLogin == 1) {
        this.informacaoEtapaLogin =
          "Informe seu número de celular e senha da sua conta para continuar:";
      }
      if (this.tipoLogin == 3) {
        this.informacaoEtapaLogin =
          "Informe os dados solicitados abaixo para criar uma conta:";
      }
      if (this.tipoLogin == 4) {
        this.informacaoEtapaLogin =
          "Informe seu nome completo e celular para continuar";
      }
      if (this.tipoLogin == 5) {
        this.informacaoEtapaLogin =
          "Informe seu nome número de celular vinculado a conta e lhe enviaremos uma mensagem o código para a recuperação de senha.";
      }
      if (this.tipoLogin == 6) {
        this.informacaoEtapaLogin =
          "Informe a nova senha e o código recebido em seu celular";
      }
    },

    obterObjetoUsuarioPorTipo(modo) {
      let user = {};

      try {
        if (modo == 3) {
          user = {
            ...this.user,
            headquarter_id: 1,
            login: this.user.cellphone
              ? this.user.cellphone.toString()
              : this.user.email,
          };
        } else if (modo == 4) {
          user = {
            ...this.user,
            headquarter_id: 1,
            login: this.user.cellphone.toString(),
            password: this.user.name,
            passwordConfirmation: this.user.name,
          };
        } else {
          user = {
            ...this.user,
            login: this.user.login.toString(),
          };
        }

        return { ...user };
      } catch (e) {
        this.setLoading(false);

        this.$notify({
          title: "Ocorreu uma falha",
          text: `
                  <p>Verifique os dados informados no formulário e envie novamente.</p>
          `,
          duration: 5000,
        });
      }
    },

    async criarConta(modo = 0) {
      try {
        this.setLoading(true);

        await api({
          method: "post",
          url: `external/users?subdomain=${this.$route.query.empresa}`,
          data: {
            user: { ...this.obterObjetoUsuarioPorTipo(this.tipoLogin) },
          },
        });

        this.realizarLogin(this.obterObjetoUsuarioPorTipo(this.tipoLogin));

        this.setLoading(false);
      } catch (e) {
        if (e.response && e.response.status == 422) {
          this.realizarLogin(this.obterObjetoUsuarioPorTipo(this.tipoLogin));
        }

        this.setLoading(false);

        console.error(e);
      }
    },

    obterInformacoesSubdominio() {
      api({
        method: "get",
        url: `/external/account_by_subdomain`,
        params: {
          subdomain: `${this.$route.query.empresa}`,
        },
      }).then((response) => {
        this.deleteItemFromLocalStorage("imagensEmpresa");
        this.empresa = {
          urlImagem: response.data.account.logo.larger,
          ...response.data.account,
        };

        this.bgImage = response.data.account.background.url;

        this.addItemToLocalStorage(`imagensEmpresa`, {
          background: response.data.account.background.larger,
          logo: response.data.account.logo.url,
        });
      });
    },

    mudarTipoLogin(novoModo) {
      this.setLoading(true);

      this.tipoLogin = novoModo;
      this.users = [];
      this.obterInputs(novoModo);
      this.obterInformacaoEtapaLogin();

      this.setLoading(false);
    },

    obterInputs(modo) {
      /*
        modo : 
          1 : Login completo
          2 : Login simplificado

          To do: 
            Criar requisicao que busque os inputs desta tela.
      */

      let retorno;

      if (modo == 1) {
        //Login comum
        retorno = [
          {
            id: "login",
            label: "Login",
            model: "login",
            placeholder: "Digite o seu login",
          },
          {
            id: "password",
            label: "Senha",
            model: "password",
            inputType: "password",
            placeholder: "Digite sua senha",
          },
        ];
      } else if (modo == 2) {
        //Cadastro simples / acesso
        retorno = [
          {
            id: "cellphone",
            label: "celular",
            model: "cellphone",
            inputType: "number",
            placeholder: "Digite seu celular",
          },
          {
            id: "name",
            label: "Nome",
            model: "name",
            placeholder: "Digite seu nome",
          },
        ];
        //Cadastro Completo
      } else if (modo == 3) {
        retorno = [
          {
            label: "Nome",
            id: "name",
            model: "name",
            placeholder: "",
            sm: "12",
            md: "6",
            lg: "3",
            xl: "3",
            cols: "12",
          },
          {
            label: "Celular",
            id: "cellphone",
            model: "cellphone",
            placeholder: "Digite o celular",
            sm: "12",
            md: "6",
            lg: "3",
            xl: "3",
            cols: "12",
          },
          {
            label: "E-mail",
            id: "email",
            model: "email",
            placeholder: "Digite o nome do produto",
            sm: "12",
            md: "6",
            lg: "3",
            xl: "3",
            cols: "12",
          },
          {
            label: "Senha",
            id: "password",
            model: "password",
            inputType: "password",
            // placeholder: "Digite o nome do produto",
            sm: "12",
            md: "6",
            lg: "3",
            xl: "3",
            cols: "12",
          },
          {
            label: "Confirmação senha",
            id: "passwordConfirmation",
            model: "passwordConfirmation",
            inputType: "password",
            // placeholder: "Digite o nome do produto",
            sm: "12",
            md: "6",
            lg: "3",
            xl: "3",
            cols: "12",
          },
        ];
      } else if (modo == 4) {
        //
        retorno = [
          {
            label: "Nome",
            id: "name",
            model: "name",
            placeholder: "",
            sm: "12",
            md: "6",
            lg: "3",
            xl: "3",
            cols: "12",
          },
          {
            label: "Celular",
            id: "cellphone",
            model: "cellphone",
            placeholder: "Digite o seu número de celular",
            sm: "12",
            md: "6",
            lg: "3",
            xl: "3",
            cols: "12",
          },
        ];
      } else if (modo == 5) {
        retorno = [
          {
            label: "Celular",
            id: "cellphone",
            model: "cellphone",
            placeholder: "Digite o número de celular vinculado a sua conta",
            sm: "12",
            md: "6",
            lg: "3",
            xl: "3",
            cols: "12",
          },
        ];
      } else if (modo == 6) {
        retorno = [
          {
            label: "Senha",
            id: "password",
            model: "password",
            sm: "12",
            md: "6",
            lg: "3",
            xl: "3",
            cols: "12",
          },
          {
            label: "Confirmação de senha",
            id: "passwordConfirmation",
            model: "passwordConfirmation",
            sm: "12",
            md: "6",
            lg: "3",
            xl: "3",
            cols: "12",
          },
          {
            label: "Código de recuperação",
            id: "code",
            model: "code",
            sm: "12",
            md: "6",
            lg: "3",
            xl: "3",
            cols: "12",
            inputType: "number",
          },
        ];
      }

      this.inputs = retorno;
    },

    async realizarLogin(dados) {
      this.setLoading(true);
      let formulario = {
        ...dados,
        subdomain: this.$route.query.empresa,
      };

      let autenticacao = await Authentication(formulario);

      this.setLoading(false);

      if (autenticacao && autenticacao.isAuth) {
        this.aplicarDadosUsuarioLogado(autenticacao);

        this.$router.push({ name: "Home" }).catch(() => {});
      } else {
        this.$notify({
          title: "Falha no login",
          text: `
                  <p>Usuário ou senha inválidos, verifique os dados informados..</p>
          `,
          duration: 5000,
        });
      }
    },

    aplicarDadosUsuarioLogado(usuario) {
      this.addItemToLocalStorage("dadosUsuarioLogado", {
        ...usuario,
        subdomain: this.$subdominio,
      });

      this.$setStoreUsuarioLogado({
        ...usuario,
        subdomain: this.$subdominio,
      });
    },

    onChildUpdate(newValue) {
      let novoValorModel = Object.keys(newValue);

      for (let key of novoValorModel) {
        if (this.user.length > 0) {
          this.user[key] = newValue;
        } else {
          this.user[novoValorModel] = newValue[novoValorModel];
        }
      }
    },
  },

  beforeDestroy() {
    // Certifique-se de remover o ouvinte de eventos ao destruir o componente
    this.$el.removeEventListener("keydown", this.handleKeyPress);
  },
};
</script>

<style lang="scss">
.card-body {
  height: 100vh !important;
  // background-color: #f2f2f2;
}

.fundo-login {
  // background:linear-gradient(0deg, rgba(204,239,255,1) 0%, rgba(0,36,61,1) 100%);
  // background-image: var(--bg-image)
  background-image: linear-gradient(
    to bottom,
    #051937,
    #143864,
    #1e5b95,
    #1e81c9,
    #00aaff
  );

  background-size: cover;
}

button {
  white-space: pre;
}

// .card { background-color: rgba(255, 255, 255, 0.4); }
// .card-header, .card-footer { opacity: 1}
</style>
