<template>
  <b-row :class="'justify-content-md-center'">
    <b-row class="mt-5">
      <b-col class="m-1" cols="11" xl="8" lg="8">
        <b-input-group size="md">
          <b-form-input
            id="filter-input"
            v-model="filtro"
            type="search"
            class="customInput"
            placeholder="Faça sua busca"
          ></b-form-input>
          <b-icon class="p-2" icon="search"></b-icon>
        </b-input-group>
      </b-col>
    </b-row>
    <b-row class="pt-2 pb-2 mt-2">
      <b-col>
        <b-table
          borderless
          :striped="isMobile()"
          :fields="fields"
          table-variant="dark"
          :items="items"
          stacked="lg"
          :filter="filtro"
          @filtered="filtrarTabela"
          dark
        >
          <template #cell(actions)="row">
            <div class="text-center custom-row-cells">
              <b-button
                variant="primary"
                class="m-1"
                @click="selecionarLinha(row.item)"
              >
                Selecionar
                <b-icon font-scale="1.3" icon="plus" variant="primary"></b-icon>
              </b-button>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <!-- <b-row>
        <b-col class="d-flex p-4 justify-content-start">
          <b 
            class="info-pagination"
          >
            Exibindo {{totalRegistros}} registros

          </b>
        </b-col>
      </b-row> -->
  </b-row>
</template>


<script>
import Mixin from "../../core/Mixin";
import api from "../../http/index";

export default {
  mixins: [Mixin],
  data: function () {
    return {
      inputs: [],
      items: [],
      fields: [],
      filtro: "",
      totalRegistros: 0,
    };
  },
  async mounted() {
    this.setLoading(true);

    this.montarCabecalhosTabela();
    await this.montarTabela();

    this.setLoading(false);
  },

  methods: {
    selecionarLinha(linha) {
      this.$emit("selecionado", linha);
    },

    filtrarTabela(itens) {
      // TO DO: Filtros e paginação via API
      this.totalRegistros = itens.length;
    },

    async obterCabecalhosTabela() {
      // to do:
      return [
        {
          key: "id",
          sortable: true,
          label: "id",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "uid",
          sortable: true,
          label: "uid",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "createdAt",
          sortable: true,
          label: "createdAt",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "updatedAt",
          sortable: true,
          label: "updatedAt",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "deletedAt",
          sortable: true,
          label: "deletedAt",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "code",
          sortable: true,
          label: "code",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "name",
          sortable: false,
          label: "",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "description",
          sortable: true,
          label: "description",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "active",
          sortable: true,
          label: "Ativo",
          thClass: "d-none",
          tdClass: "d-none",
        },
        {
          key: "code",
          sortable: false,
          label: "Código",
        },
        {
          key: "suggestedPrice",
          label: "",
          formatter: (value) => {
            return (
              value &&
              value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            );
          },
          sortable: false,
          thClass: "text-center",
          tdClass: "text-center",
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger'
        },
        {
          key: "actions",
          label: "",
          thClass: "text-center",
          tdClass: "text-center",
          // sortable: true,
          // Variant applies to the whole column, including the header and footer
          // variant: 'danger'
        },
      ];
    },

    async montarCabecalhosTabela() {
      this.fields = await this.obterCabecalhosTabela();
    },

    async montarTabela() {
      this.items = await this.obterProdutos();
    },

    async obterProdutos() {
      let retorno = await api({
        method: "GET",
        url: `/products?key_transform_camel_lower=true`,
        params: {
          sort_direction: "desc",
          sort_property: "id",
          count: true,
          per_page: 9999,
          page: 1, /// pegar a pgina atual da tabela
        },
      });

      this.totalRegistros = retorno.data.count;

      return retorno.data.products;
    },
  },
};
</script>

<style lang="scss" scoped>
.b-table > tbody > tr:hover {
  background-color: red;
}
</style>