import Vue from 'vue';
import { mapActions } from 'vuex';

const mixin = {

    data() {
        return {
            cleanupTimer: undefined,
        };
    },

    created() {
    },

    mounted() {        
        this.$setStoreUsuarioLogado(this.getItemsFromLocalStorage("dadosUsuarioLogado")[0]);
    },

    updated() {
        this.$setStoreUsuarioLogado(this.getItemsFromLocalStorage("dadosUsuarioLogado")[0]);
    },

    methods: {
        ...mapActions(['setLoading']),

        addItemToLocalStorage(key, item) {
            let items = this.getItemsFromLocalStorage(key);
            items.push(item);
            localStorage.setItem(key, JSON.stringify(items));
        },
          
          // Obter itens do Local Storage
        getItemsFromLocalStorage(key) {
            let items = localStorage.getItem(key);
            return items ? JSON.parse(items) : [];
        },
      
          // Excluir um item do Local Storage
        deleteItemFromLocalStorage(key) {
            localStorage.removeItem(key);
        },

        obterTipoPerfilUsuarioLogado(){
            let dadosUserLogado = this.getItemsFromLocalStorage("dadosUsuarioLogado")[0];

            if(dadosUserLogado && dadosUserLogado.user){
                if(dadosUserLogado.user.profileType && dadosUserLogado.user.profileType.id == 1){//funcionario
                    if(dadosUserLogado.user.isAccountAdmin){
                        return "ADMIN";
                    };

                    return "FUNCIONARIO";
                };
            };

            return "CLIENTE";
        },

        obterRotaPorTipoUsuario(rotaOriginal){
            // if(this.obterTipoPerfilUsuarioLogado() != "ADMIN"){
            //     return `/homepages/${rotaOriginal}`; // Caso precise manipular rota por tipo de usuario
            // };
            return rotaOriginal;
        },


        isMobile(){
            return window.innerWidth <= 768;
        },

        arredondarTimestampParaBaixo(timestamp, duration) {
            const dt = new Date(timestamp);

            // Arredondar para baixo para o intervalo de 30 minutos
            const minutes = dt.getMinutes();
            const roundedMinutes = Math.floor(minutes / duration) * duration;
            dt.setMinutes(roundedMinutes, 0, 0);
          
            // Obter o timestamp arredondado em milissegundos
            const roundedTimestamp = dt.getTime();
          
            return roundedTimestamp;
        },

        // Função para converter a data para uma forma humanizada em português
        humanizarTimestamp(timestamp) {
            const meses = [
                "janeiro", "fevereiro", "março", "abril", "maio", "junho",
                "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"
              ];
            
              const data = new Date(timestamp);
              const dia = data.getDate();
              const mes = meses[data.getMonth()];
              const ano = data.getFullYear();
              const hora = data.getHours();
              const minutos = data.getMinutes();
            
              return `${dia} de ${mes} de ${ano}, às ${hora}h${minutos.toString().padStart(2, '0')}`;
        },
        //Cria e atualiza a variavel userData no vuex
        $setStoreUsuarioLogado(data){
            this.$store.commit(`setPropriedades`, {dadosUsuarioLogado : {...data}});        
        },
        
        //Cria e atualiza a variavel servicosOferecidos no vuex
        $setStoreServicosOferecidos(data){
            this.$store.commit(`setPropriedades`, {servicosOferecidos : {...data}});        
        },

        $setStoreServicosAgendados(data){
            this.$store.commit(`setPropriedades`, {servicosAgendados : data});        
        },

        $setStoreServico(data){
            this.$store.commit(`setPropriedades`, {servico : data});        
        },

        $setStoreComanda(data){
            this.$store.commit(`setPropriedades`, {comanda : data});        
        },

        //Imagens da empresa
        $setStoreImagensEmpresa(data){
            this.$store.commit(`setPropriedades`, {imagensEmpresa : {...data}});        
        },

        $getStore(key){
            return this.$store.getters.getPropriedades[key];
        },

        $clearStore(key){
            try{
                this.$store.getters.getPropriedades[key] = undefined;
            }catch(e){
                console.error(e, "$clearStore");
            };
        },

    },

};
 
export default mixin;